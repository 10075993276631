.body-wrapper {
  background-color: lightblue;
  padding: 10px;
  margin: 0 auto;
  width: 100%; /* Limit maximum width */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.products-container {
  width: 100%;
  height: 100vh; /* Set a fixed height or adjust as necessary */
  overflow-y: auto; /* Enable scrolling if content exceeds the height */
  padding: 0px; /* Add spacing inside the container */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow for elevation */
  display: flex;
  gap: 3px; /* Reduced gap between products */
  flex-wrap: wrap; /* Allow wrapping if the items are too wide */
}

.product {
  height: 30px; /* Set a fixed height or adjust as necessary */
  padding: 2px; /* Add spacing inside the container */
  background-color: #f9f9f9; /* Optional: Add background color for visibility */
  border: 1px solid #ddd; /* Optional: Add a border for clarity */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow for elevation */
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}


.left-column {
  width: 70%; /* Set left column to 80% width */
  padding-right: 10px; /* Reduce the right margin within the left column */
}

.right-column {
  width: 20%; /* Set right column to 20% width */
  padding-left: 10px; /* Reduce the left margin within the right column */
}

.left-column .products-container,
.right-column .test-schedule-wrapper {
  margin-bottom: 20px; /* Reduced gap between sections */
}

.test-schedule-wrapper {
  display: none;
  margin-top: 10px; /* Adjust vertical spacing */
  padding: 10px; /* Optional padding for clarity */
  flex-shrink: 0; /* Prevent shrinking of TestSchedule */
  align-self: flex-start; /* Align TestSchedule closer to the top */
  margin-left: 0; /* Reduce the left margin for test-schedule-wrapper */
}

.test-schedule {
  width: 100%; /* Ensure it spans the full width of its container */
  max-width: 30vw; /* Limit maximum width of the schedule */
}

.mt-3 {
  margin-top: 1rem; /* Adjusted margin for ProductList */
}

.mt-4 {
  margin-top: 1.5rem; /* Reduced the top margin to reduce spacing */
}

@media (max-width: 768px) {
  .d-flex {
    flex-direction: column; /* Stack columns vertically on smaller screens */
  }

  .left-column,
  .right-column {
    width: 100%; /* Ensure columns stack and occupy full width on small screens */
  }
.test-schedule-wrapper {
  display:none !important;
}
  .products-container {
    gap: 15px; /* Adjust gap on smaller screens */
  }
}
