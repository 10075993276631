
.product-container {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  gap: 20px; /* Add space between product cards */
  justify-content: center; /* Center products */
}

.product-card {
  position: relative; /* Allows positioning of child elements inside the card */
  flex: 1 1 300px; /* Allow growing to full width if necessary */
max-width: 100%; /* Prevent overflowing */
  border: none;
  padding: 0;
  margin: 0;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   background-color: #fff; /* White background */
}

.product-card img {
  max-width: 100%; /* Ensure images fit within the product card */
  height: 200px; /* Fixed height for consistent card sizes */
  object-fit: cover; /* Ensures the image maintains aspect ratio without distortion */
  border-radius: 8px;
}
/* Container for the small image and estore name */
.product-card .estore-info {
  position: absolute;
  top: .3em; /* Position from the top */
  left: .3em; /* Position from the left */
  display: flex; /* Align items in a row */
  align-items: center; /* Vertically align items */
  background: rgba(0, 0, 0, 0.2); /* Translucent black background */
  padding: 5px 3px 0px 0px; /* Padding inside the row */
  border-radius: 8px; /* Rounded corners */
  color: white; /* Text color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Small image inside the row */
.product-card .estore-info .small-image {
  width: 40px; /* Set width for the small image */
  height: 40px; /* Set height for the small image */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 50%; /* Circular shape */
  border: 2px solid white; /* Optional border for visibility */
  margin-right: 6px; /* Space between image and text */
}

/* E-store name */
.product-card .estore-info .estore-name {
  font-size: .7rem; /* Font size */
  font-weight:lighter; /* Bold text */
  white-space: nowrap; /* Prevent text from wrapping */
}

.product-card h3 {
  font-size: 1.5rem;
  /* margin: 10px 0; */
}

.product-card p {
  font-size: 1rem;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

button {
  display: flex;
  flex-direction: row;
  border-radius: 75px;
  align-items: center;
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #218838;
}

.add-icon {
  margin-right: 8px;
  font-size: 18px;
}
/* Ensure full width for small screens */
@media (max-width: 600px) {
  .product-card {
    flex: 1 1 100%; /* Full width on small screens */
  }
}