.cart-container {
  margin: 10px;
}

.cart-list {
  list-style: none;
  padding: 0;
}

.cart-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.cart-item-details {
  display: flex;
  justify-content: space-between; /* Ensures the button is at the far end */
  align-items: center; /* Align items vertically in the middle */
  margin-inline: 5px;
}

.cart-item-info {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.cart-item-name, .cart-item-price, .cart-item-quantity {
  margin-bottom: 5px;
}

.cart-item-quantity-input {
  width: 60px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 2.5px;
}
.cart-item-quantity button {
  margin-right: 1px;
  margin-top: 0px;
  width: 25px;
  height: 25px;
  border-radius: 28%;
  background-color:#1eb6f1;
}
.cart-item-quantity {
  display: flex;
  flex-direction: row;
   width: 70px; 

}

.remove-btn {
  /*width:125px; */
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center; /* Aligns the icon and text vertically */
  padding: 5px 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.add-icon {
  margin-right: 8px; /* Adds space between the icon and text */
  font-size: 18px; /* Adjusts the size of the icon */
}

.remove-btn:hover {
  background-color: #c0392b; /* Darker color on hover */
}



img {
  max-width: 70px;
  height: auto;
  border-radius: 8px;
  margin-right: 10px; /* Adds space between the image and text */
}
@media (max-width: 768px) {
  /* Ensure descriptions wrap */
  .cart-item-details {
    flex-direction: column; /* Switch to column layout on small screens */
    align-items: flex-start; /* Align items to the left */
  }

  /* Make checkout button sticky */
  .checkout-btn {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 300px;
    text-align: center;
    background-color: #269faf;
    color: white;
    padding: 10px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
}