.qrcode__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}

.qrcode__container--parent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px; /* Ensures content does not overflow */
  padding: 10px;
  overflow: hidden; /* Prevents overflow */
}

.qrcode__input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  max-width: 300px; /* Prevents stretching */
  margin-top: 20px;
}

.qrcode__input label {
  text-align: center;
  word-break: break-word; /* Prevents long text from overflowing */
  max-width: 100%;
}

.qrcode__input button,
.qrcode__download button {
  width: 100%; /* Ensures button stays within container */
  max-width: 250px; /* Prevents stretching */
  padding: 10px;
}

.qrcode__download {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  max-width: 100%;
  width:100%;
  height: auto;
}

.qrcode__download .qrcode__image {
  width:100%;
  max-width: 100%;
  overflow: hidden;
  height:auto;
}

.qrcode__download button {
  margin-top: 15px;
}
 input[type="checkbox"] {
      margin: 0;
    }
    label {
      margin-left: 0;
    }