
.estore-orders-wrapper {
  padding: 15px;
}

.row {
  margin-bottom: 20px;
}
.table {
  margin-bottom: 20px;
}

.card {
  margin-bottom: 10px;
}

.nested-table {
  width: 100%;
  border-collapse: collapse;
}

.nested-table th, .nested-table td {
  padding: 8px;
  text-align: left;
}

.seen {
  background-color: #dff0d8;
}

.nested-table th,
.nested-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.expanded-details {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-top: 10px;
}

.order-list-container {
  padding: 10px;
}

.order-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-name {
  font-weight: bold;
}

.order-details {
  margin-top: 10px;
  font-size: 14px;
}
.btn{
  margin-top: 2px;
}
button {
  
  cursor: pointer;
}

.expanded-details {
  margin-top: 10px;
}

.order-item-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
/* Add this to your index.css or a relevant stylesheet */
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px; /* Adjust the max-width as needed */
}
/* Apply border-radius to the table itself */
.table {
  border-collapse: separate; /* Required for borders to respect radius */
  border-spacing: 0;
  width: 100%;
  border-radius: 10px; /* Rounded corners for the table */
  overflow: hidden; /* Ensure content doesn't overflow the rounded corners */
}
@media (max-width: 768px) {
  .display-none-right {
    display: none;
  }
  .display-block-down {
    display: block;
  }
}
@media (min-width: 768px) {
  .display-block-right {
    display: block;
  }
  .display-block-down {
    display: none;
  }
}