.liquid-toppings-container {
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.liquid-toppings-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 5px;
  color: #333;
}

.liquid-toppings-list {
  display: flex;
  flex-wrap: wrap; /* Enables wrapping of list items */
  padding: 0;
  margin: 0;
}

.liquid-toppings-list-item {
  display: flex;
  align-items: center;
}

.liquid-toppings-list-item input[type='checkbox'] {
  margin-right: 1px;
}

.liquid-toppings-input {
  width: 100%;
  padding: 8px;
  margin-right: 1px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.liquid-toppings-button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.liquid-toppings-button:hover {
  background-color: #45a049;
}
.check-input{
  margin: 0;
        padding: 0;
        display:block;
        height:12px;
        overflow:hidden;
}
