.product-list-wrapper {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px;
   width: 100%;
  flex-wrap: wrap; 
}

/* Top Row Styles */
.top-box-container {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  margin-bottom: 10px;
}

.top-box {
  flex: 1;
  padding: 10px;
  text-align: center;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.top-box.unavailable {
  background-color: red;
}

/* Product List */
.product-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.product-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.product-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.product-name {
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.product-actions {
  display: flex;
  gap: 10px;
}

.product-actions svg {
  cursor: pointer;
  font-size: 1.2rem;
}

/* Unavailable Products */
.unavailable-products {
  display:inline-block; /* Ensure it stays visible */
  width: 100%; /* Full width */
  min-height: 50px; /* Set a minimum height to prevent collapse */
  background-color: #fff3f3;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .top-box-container {
    flex-wrap: wrap;
  }

  .top-box {
    flex: 1 1 45%;
  }

  .product-item {
    flex-direction: column;
    text-align: center;
  }

  .product-details {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .product-actions {
    flex-direction: row;
    justify-content: center;
  }
  .unavailable-products {
  display: none !important; /* Prevents accidental hiding */
  display: none;
  }
}
.custom-button {

  background-color: #007bff; /* Blue background color */

  color: white; 

  border: none;

  padding: 5px 10px; 

  border-radius: 35px; 

  cursor: pointer;

  transition: background-color 0.3s ease; /* Smooth transition on hover */

}



.custom-button:hover {

  background-color: #0069d9; /* Slightly darker blue on hover */

}
