/* Container for the product list */
.product-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  padding: 10px;
}
.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  padding: 10px;
  border-bottom: 0px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  background: #fff;
}
/* Style for individual item */
/* .product-item {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}
 */
.product-item:hover {
  transform: scale(1.05);
}

/* Buttons */
button {
    width:auto;
    height: auto;
  padding: 5px 10px;
  margin: 5px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background: #0056b3;
}

.delete-btn {
  background: red;
}

.delete-btn:hover {
  background: darkred;
}

.estore-item-quantity-input {
  width: 60px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 2.5px;
}
.estore-item-quantity button {
  margin-right: 1px;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  border-radius: 28%;
  background-color:#1eb6f1;
}
.estore-item-quantity {
  display: flex;
  flex-direction: row;
   width: 70px; 
   margin-right: auto;
   margin-left: auto;

}
.product-name {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  max-width: 150px;
  flex-wrap: wrap;
  min-height: 14px;
}

.product-details {
  flex: 1; /* Takes up remaining space */
  /* height: 20px; */
  flex-direction: row;
}

.item-actions {
  display: flex;
  gap: 10px; /* Space between buttons */
}

/* Move buttons to a new line on small screens */
@media (max-width: 600px) {
  .product-item {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start;
  }

  .item-actions {
    margin-top: 10px; /* Add spacing */
    width: 100%; /* Ensure full width */
    display: flex;
    justify-content: flex-start;
  }
}
.blurred {
  filter: blur(3px);
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.ellipsis-btn {
  cursor: pointer;
  padding: 5px;
}

.dropdown-menu {
  /* position: absolute; */
   top: 0px; 
  right: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  min-width: 100px;
}

.dropdown-menu button {
  /* background: none; */
  border: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

/* .dropdown-menu button:hover {
  background: #f5f5f5;
} */